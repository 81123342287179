.qs-link {
  &.qs-link-negative-small {
    margin: -5px 0 0 0;
  }
  &.qs-link-none {
    margin: 0;
  }
  &.qs-link-padded {
    margin: 10px 0 10px 0;
  }

  &:hover {
    color: #151983 !important;
  }
  
}