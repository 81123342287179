@import "../../colors";
@import "../../sizes";

.mobile-report-menu {
  display: flex;
  padding: 7px;

  button {
    height: $mobile-report-menu-height;
    border: 1px solid $mobile-report-border !important;
    background-color: $mobile-menu-item !important;

    span {
      color: #111111;
    }
  }

  .date-btn {
    display: flex;
    width: 100%;
    align-items: center;

    span {
      flex-grow: 1;
      text-align: left;
    }
  }

  .info-btn {
    width: $mobile-report-menu-height;
    margin-right: 0;
  }
}

.mobile-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: hidden;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;

  .menu {
    justify-content: space-evenly;

    a {
      background-color: $mobile-menu-item !important;
      height: $mobile-report-menu-height;
      border-bottom-width: 3px !important;
      flex: 1 !important;
      justify-content: center;
      font-weight: bold !important;

      &.active {
        border-bottom-color: #a8a8a8 !important;
      }

      &:active {
        border-color: transparent !important;
      }
    }
  }

  .pane {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f7f7f7;
    padding: 9px;

    &.with-header {
      top: $mobile-report-menu-height;
    }
  }

  .pane-container {
    height: 100%;
    padding: 18px;
    border: #dbdbdb 1px solid;
    border-radius: 3px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .sub.header {
      margin-top: 20px;
      text-transform: none;
    }

    .pane-body {
      margin-top: 20px;
      margin-bottom: 16px;
      flex-grow: 1;

      &.scroll {
        overflow: auto;
        margin-top: 10px;
        margin-bottom: 16px;
      }

      button,
      input {
        -webkit-appearance: none;
        color: #111111;
        margin-bottom: 4px;
        background-color: white;
        border: 1px solid $mobile-report-border;
        border-radius: 5px;
        height: $mobile-report-button-height;
        position: relative;
        text-align: center;
        font-weight: bold;

        i {
          position: absolute;
          left: 15px;
        }

        &.selected {
          background-color: $mobile-menu-item;
        }
      }

      i.icon {
        height: $mobile-report-button-height;
      }

      .toggle.checkbox {
        margin-top: 24px;
      }

      .label {
        width: 100%;
        text-align: center;
      }
    }
  }

  label {
    font-weight: bold;
    text-decoration: underline;

    &.padded {
      line-height: 44px;
    }
  }

  span.label {
    font-weight: bold;

    &:after {
      content: " - ";
    }
  }
}
