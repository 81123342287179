@import '../../colors';
@import '../../sizes';

.big-number-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: $card-background;
  padding: 0 10px 10px 10px;
  text-align: center;
  color: $base-text-color;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  grid-template-rows: 1.1fr 3fr;
  grid-template-areas:
    "title title title"
    ". value .";

  .big-number-title {
    grid-area: title;
    font-size: 17px;
    color: lighten($base-text-color, 15%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 -10px;
    border-radius: 8px 8px 0px 0px;
    background-color: $title-background;
    border-bottom: 1px solid #dbdbdb;

    @media (max-width: 860px) {
      font-size: 14px;
    }
  }

  &.nonCompare .big-number-value {
    .attribute-positive {
      color: inherit;
    }
    .attribute-negative {
      color: inherit;
    }
  }

  .big-number-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: value;
    font-size: 32px;
    @media (max-width: 860px) {
      font-size: 22px;
    }
    .attribute-positive {
      color: $dashboard-positive;
    }
    .attribute-negative {
      color: $dashboard-negative;
    }
    i {
      margin-right: 5px;
    }

    .percent-change-attribute {
      display: block;
      font-size: $dashboard-text;
    }
    .big-number-attribute {
      font-size: $dashboard-text;
      margin-top: 12px;
      @media (max-width: 860px) {
        font-size: 12px;
      }
      .attribute-positive {
        color: $dashboard-positive;
      }
      .attribute-negative {
        color: $dashboard-negative;
      }
      i {
        margin-right: 5px;
      }
    }
  }

}
