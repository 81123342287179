@import "../../_colors";

.qs-icon {
  &.qs-icon-none {
    margin: 0;
  }
  &.qs-icon-smpadded {
    margin: 0 .35em 0 0;
  }
  &.qs-icon-padded {
    margin: 0 .75em 0 0;
  }
  &.qs-icon-xpadded {
    margin: 0 1.5em 0 0;
  }
  &.qs-icon-paddedlt {
    margin: 0 0 0 .75em;
  }
  &.qs-icon-paddedsmlt {
    margin: 0 0 0 .35em;
  }
  &.qs-icon-paddedsmboth {
    margin: 0 .35em;
  }
  
  &.avatar {
    margin: 0 auto;
    max-width: 28px;
    width: 28px;
    max-height: 28px;
    height: 28px;
    vertical-align: middle;
  }
}

i.qsfa {
  &.avatar-container {
    display: inline-block;
    color: $avatar-color;
    line-height: 50px;
    text-align: center;
    border-radius: 100%;
    width: 55px;
    height: 55px;
    background-color: $avatar-background;
  }
}
