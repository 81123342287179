@import './colors';

// Default global styles

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}


.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.qs-app-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  z-index: 1;
  color: $base-text-color;
}

.qs-viewport {
  flex: 1 1 auto;
  height: calc(100vh - 50px);
  overflow: auto;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  background-color: white;
  z-index: 0;
}

.ui.tabular.menu a.active.item {
  color: $base-text-color;
}

.ui.page.modals.transition.visible {
  display: flex !important;
}

.rc-time-picker-panel-select li {
  width: initial;
}

.rc-time-picker-input {
  height: 38px;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.draggable-ref, .draggable-ref-item {
  margin-bottom: .5rem;
}

.ui.orange.rightMargin {
    margin-right: 2em;
}

.view-background-color {
  background: $view-background-color;
}
