@import '../../colors';

.qs-avatar {
  color: #fff;
  font-weight: bold;

  .top-nav {
    div:first-child {
      border: 2px solid $nav-header-border;
      line-height: 28px !important;
    }
  }
  
}
