@import './colors';

.qs-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 250px;
  flex: 0 0 auto;

  .ui.vertical.menu { 
    z-index: 0;

    .header {
      color: $base-text-color;
    }

    .select-wrap {
      position: relative;
      z-index: 10;
      padding: 0;

      i {
        .pointer-down,
        .clear-button {
          z-index: 25;
          position: absolute;
          top: 14px;
          right: 4px;
        }

        .clear-button {
          cursor: pointer;
        }
      }

      .nav-search {
        position: relative;
        z-index: 15;
        background-color: $sidebar-selection-background;
        border-radius: 0;
        border-width: 0;

        .loading{
          .loading.icon,
          .yellow.loading.icon {
            margin-left: 10px;
          }
        }

        .icon {
          .yellow.loading.icon {
            margin-left: 10px;
          }
        }

        &.selection {
          background-color: #fff;
          i {
            .left-icon {
              display: none;
            }
          }
        }

        .input {
          height: 100%;
          padding-left: 10px;

          input {
            height: 100%;
            min-height: 40px;
          }

          i {
            .left-icon {
              position: absolute;
              left: 1.5em;
              top: 16px;
            }
          }
        }

        i {
          .pinned-tack {
            position: absolute;
            left: 1.5em;
            top: 16px;
          }
        }

        .scroll-container {
          position: absolute;
          top: 40px;
          z-index: 50;
          width: 100%;
          background: #fff;
          max-height: 300px;
          overflow-y: scroll;
          box-shadow: 0px 7px 8px -8px rgba(0,0,0,0.29);
          border-bottom: 1px solid $sidebar-item-border;

          .ui.table {
            margin-top: 0;

            .resource-icon {
              margin-left: 1.5em;
            }
          }
        }

        .no-results {
          margin-left: 1.2em;
        }

      }
    }

    .item {
      margin: 0;
      padding: 13px 16px;

      &.main {
        border-radius: 0;
        background-color: $sidebar-header-background;
        .header {
          font-weight: bold;
        } 
      } 

      .header {
        font-weight: normal;
        margin: 0;
      } 
      
      &:before {
        background: transparent;
        height: 0;
      }
    } 

    a.item {
      font-weight: normal;
      color: $base-text-color;
      border-top: 1px dashed $sidebar-item-border;

      &.insights,
      &.users,
      &.user {
        border-top: none;
      }
      
      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: 1px solid $sidebar-item-border;
      }

    }
  }

  .qs-sidebar-top {
    flex: 1;
  }

  .qs-sidebar-bottom {
    flex: 0 0 auto;
  }

  &.collapsed {
    width: 50px;
  }
}

.qs-sidebar-menu {
  height: 100%;
  width: 250px;  
  border-radius: 0;
  border-top: none;
}