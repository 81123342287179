

.avatar-header {

  a.contact-info {
    display: block;
    margin-top: -5px;
  }

}
