@import '../../colors';

.ui.segment.key-group {
  .ui.grid {

    &.key-value {
      border-bottom: 1px solid $key-value-border;

      .row {
        padding-top: 0;
        padding-bottom: 0;

        .column {
          padding-left: 0;
          padding-right: 0;

          .key {
            background-color: $key-value-background;
            font-weight: bold;
            border-right: 1px solid $key-value-border;
            white-space: nowrap;
            padding-right: 20px;
          }

          .value {

            &.bright-blue {
              color: $key-value-highlight;
            }
          }

        }
      }

      &:last-child {
        border-bottom: none;
      }

    }
  }
}

.ui.grid {
  &.plain-grid {

    .row {
      padding-top: 0;
      padding-bottom: 0;

      .column {
        padding-left: 0;
        padding-right: 0;

        .key {
          font-weight: bold;
        }
       
      }
    }

  }
}

