$qs-dark-grey: #272425;

$border-color: #eee;

$border-color-dark: #dbdbdb;

$login-container-bg-color: $qs-dark-grey;

$card-background: #fff;

$base-text-color: #555;

$dark-text-color: #333;

$top-nav-color: #fff;

$title-background: #f2f2f2;

$dashboard-positive: #008000;

$dashboard-negative: #ff0000; 

$dashboard-border: #dbdbdb;

$nav-header-border: #fff;

$dropdown-background: #f2f2f2;

$dropdown-border: #d3d3d3;

$mobile-menu-item: #e9e9e9;

$mobile-report-border: #c7c7c7;

$account-border: #c7c7c7;

$sidebar-header-background: #d0d0d0;

$sidebar-selection-background: #F2F2F2;

$sidebar-item-border: #d5d5d5;

$sidebar-active-background: #707070;

$sidebar-hover-background: #EBF2F5;

$placeholder-text: #707070;

$table-border-account: #707070;

$key-value-border: #d4d4d4;

$key-value-background: #f2f2f2;

$key-value-highlight: #1C8198;

$avatar-color: #fff;

$avatar-background: #151983;

$view-background-color: #F7F7F7;
