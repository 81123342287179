.mobile-advert {
  background: linear-gradient(#fff, #f0f0f0);
  height: 100%;

  .italic {
    font-style: italic;
  }

  .device-image {
    background-position: top center;
    background-size: contain;
    height: 1300px;
    background-repeat: no-repeat;
  }

  .toplogo {
    height: 130px;
    background: url('https://cdn.quikserve.com/logos/quikserve/qs-logo-standard.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
}