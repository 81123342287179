@import url(https://fonts.googleapis.com/css?family=Montserrat);
.qs-icon.qs-icon-none {
  margin: 0; }

.qs-icon.qs-icon-smpadded {
  margin: 0 .35em 0 0; }

.qs-icon.qs-icon-padded {
  margin: 0 .75em 0 0; }

.qs-icon.qs-icon-xpadded {
  margin: 0 1.5em 0 0; }

.qs-icon.qs-icon-paddedlt {
  margin: 0 0 0 .75em; }

.qs-icon.qs-icon-paddedsmlt {
  margin: 0 0 0 .35em; }

.qs-icon.qs-icon-paddedsmboth {
  margin: 0 .35em; }

.qs-icon.avatar {
  margin: 0 auto;
  max-width: 28px;
  width: 28px;
  max-height: 28px;
  height: 28px;
  vertical-align: middle; }

i.qsfa.avatar-container {
  display: inline-block;
  color: #fff;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  width: 55px;
  height: 55px;
  background-color: #151983; }

.big-number-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 0 10px 10px 10px;
  text-align: center;
  color: #555;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  grid-template-rows: 1.1fr 3fr;
  grid-template-areas: "title title title" ". value ."; }
  .big-number-container .big-number-title {
    grid-area: title;
    font-size: 17px;
    color: #7b7b7b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 -10px;
    border-radius: 8px 8px 0px 0px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #dbdbdb; }
    @media (max-width: 860px) {
      .big-number-container .big-number-title {
        font-size: 14px; } }
  .big-number-container.nonCompare .big-number-value .attribute-positive {
    color: inherit; }
  .big-number-container.nonCompare .big-number-value .attribute-negative {
    color: inherit; }
  .big-number-container .big-number-value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: value;
    font-size: 32px; }
    @media (max-width: 860px) {
      .big-number-container .big-number-value {
        font-size: 22px; } }
    .big-number-container .big-number-value .attribute-positive {
      color: #008000; }
    .big-number-container .big-number-value .attribute-negative {
      color: #ff0000; }
    .big-number-container .big-number-value i {
      margin-right: 5px; }
    .big-number-container .big-number-value .percent-change-attribute {
      display: block;
      font-size: 14px; }
    .big-number-container .big-number-value .big-number-attribute {
      font-size: 14px;
      margin-top: 12px; }
      @media (max-width: 860px) {
        .big-number-container .big-number-value .big-number-attribute {
          font-size: 12px; } }
      .big-number-container .big-number-value .big-number-attribute .attribute-positive {
        color: #008000; }
      .big-number-container .big-number-value .big-number-attribute .attribute-negative {
        color: #ff0000; }
      .big-number-container .big-number-value .big-number-attribute i {
        margin-right: 5px; }

.ui.segment.key-group .ui.grid.key-value {
  border-bottom: 1px solid #d4d4d4; }
  .ui.segment.key-group .ui.grid.key-value .row {
    padding-top: 0;
    padding-bottom: 0; }
    .ui.segment.key-group .ui.grid.key-value .row .column {
      padding-left: 0;
      padding-right: 0; }
      .ui.segment.key-group .ui.grid.key-value .row .column .key {
        background-color: #f2f2f2;
        font-weight: bold;
        border-right: 1px solid #d4d4d4;
        white-space: nowrap;
        padding-right: 20px; }
      .ui.segment.key-group .ui.grid.key-value .row .column .value.bright-blue {
        color: #1C8198; }
  .ui.segment.key-group .ui.grid.key-value:last-child {
    border-bottom: none; }

.ui.grid.plain-grid .row {
  padding-top: 0;
  padding-bottom: 0; }
  .ui.grid.plain-grid .row .column {
    padding-left: 0;
    padding-right: 0; }
    .ui.grid.plain-grid .row .column .key {
      font-weight: bold; }

.qs-avatar {
  color: #fff;
  font-weight: bold; }
  .qs-avatar .top-nav div:first-child {
    border: 2px solid #fff;
    line-height: 28px !important; }

.wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.qs-loading-text {
  font-family: Montserrat;
  font-size: 1.5em;
  color: #fbae17;
  display: inline-block;
  margin-left: 5px; }

.qs-loading-bounceball {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 15px; }
  .qs-loading-bounceball:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fbae17;
    transform-origin: 50%;
    -webkit-animation: bounce 500ms alternate infinite ease;
            animation: bounce 500ms alternate infinite ease; }

@-webkit-keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2); }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1); }
  100% {
    top: 0; } }

@keyframes bounce {
  0% {
    top: 30px;
    height: 5px;
    border-radius: 60px 60px 20px 20px;
    transform: scaleX(2); }
  35% {
    height: 15px;
    border-radius: 50%;
    transform: scaleX(1); }
  100% {
    top: 0; } }

.avatar-header a.contact-info {
  display: block;
  margin-top: -5px; }

.qs-link.qs-link-negative-small {
  margin: -5px 0 0 0; }

.qs-link.qs-link-none {
  margin: 0; }

.qs-link.qs-link-padded {
  margin: 10px 0 10px 0; }

.qs-link:hover {
  color: #151983 !important; }

.qs-panel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  margin-top: 50px;
  background-color: rgba(1, 1, 1, 0);
  visibility: hidden;
  transition: visibility 500ms linear, background-color 500ms linear;
  z-index: 1000000; }
  .qs-panel-container.visible {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
    transition: visibility 500ms linear, background-color 500ms linear; }
  .qs-panel-container .qs-panel {
    width: 0px;
    height: calc(100vh - 50px);
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    background-color: #FFF;
    opacity: 1;
    visibility: visible;
    overflow-y: auto;
    overflow-x: hidden; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px; }

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-intro {
  font-size: large; }

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.qs-app-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  z-index: 1;
  color: #555; }

.qs-viewport {
  flex: 1 1 auto;
  height: calc(100vh - 50px);
  overflow: auto;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  background-color: white;
  z-index: 0; }

.ui.tabular.menu a.active.item {
  color: #555; }

.ui.page.modals.transition.visible {
  display: flex !important; }

.rc-time-picker-panel-select li {
  width: auto;
  width: initial; }

.rc-time-picker-input {
  height: 38px; }

.fade-enter {
  opacity: 0;
  z-index: 1; }

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in; }

.draggable-ref, .draggable-ref-item {
  margin-bottom: .5rem; }

.ui.orange.rightMargin {
  margin-right: 2em; }

.view-background-color {
  background: #F7F7F7; }

.qs-login-container {
  width: 100vw;
  height: 100vh;
  padding: env(save-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background: white;
  background: linear-gradient(180deg, white 35%, #f3f3f3 100%);
  overflow: hidden; }
  .qs-login-container .qs-pin-input input {
    text-align: center; }
  .qs-login-container .qs-pin-input input[type=number]::-webkit-inner-spin-button,
  .qs-login-container .qs-pin-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .qs-login-container .qs-pin-input input[type=number],
  .qs-login-container .qs-pin-input input[type=number] {
    -webkit-text-security: disc; }
  .qs-login-container a.link {
    cursor: pointer; }

.ui.container.account-screen {
  width: 100%;
  height: 100vh;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%, #ebebeb 50%, #f0f0f0 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  .ui.container.account-screen img.ui.image.account-logo-medium {
    width: 268px;
    height: 52px;
    -o-object-fit: cover;
       object-fit: cover;
    margin-top: 50px;
    margin-bottom: 30px; }
  .ui.container.account-screen h2.ui.header.account-header {
    color: #333;
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 1em 0; }
  .ui.container.account-screen .info-box {
    width: 788px;
    margin: 0 auto;
    padding-bottom: 2em;
    border: 1px solid #c7c7c7;
    text-align: center; }
    .ui.container.account-screen .info-box .form-container {
      width: 630px;
      margin: 0 auto;
      text-align: left; }
      .ui.container.account-screen .info-box .form-container p:first-child {
        margin-top: 1.5em; }

.qs-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 250px;
  flex: 0 0 auto; }
  .qs-sidebar .ui.vertical.menu {
    z-index: 0; }
    .qs-sidebar .ui.vertical.menu .header {
      color: #555; }
    .qs-sidebar .ui.vertical.menu .select-wrap {
      position: relative;
      z-index: 10;
      padding: 0; }
      .qs-sidebar .ui.vertical.menu .select-wrap i .pointer-down,
      .qs-sidebar .ui.vertical.menu .select-wrap i .clear-button {
        z-index: 25;
        position: absolute;
        top: 14px;
        right: 4px; }
      .qs-sidebar .ui.vertical.menu .select-wrap i .clear-button {
        cursor: pointer; }
      .qs-sidebar .ui.vertical.menu .select-wrap .nav-search {
        position: relative;
        z-index: 15;
        background-color: #F2F2F2;
        border-radius: 0;
        border-width: 0; }
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .loading .loading.icon,
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .loading .yellow.loading.icon {
          margin-left: 10px; }
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .icon .yellow.loading.icon {
          margin-left: 10px; }
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search.selection {
          background-color: #fff; }
          .qs-sidebar .ui.vertical.menu .select-wrap .nav-search.selection i .left-icon {
            display: none; }
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .input {
          height: 100%;
          padding-left: 10px; }
          .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .input input {
            height: 100%;
            min-height: 40px; }
          .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .input i .left-icon {
            position: absolute;
            left: 1.5em;
            top: 16px; }
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search i .pinned-tack {
          position: absolute;
          left: 1.5em;
          top: 16px; }
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .scroll-container {
          position: absolute;
          top: 40px;
          z-index: 50;
          width: 100%;
          background: #fff;
          max-height: 300px;
          overflow-y: scroll;
          box-shadow: 0px 7px 8px -8px rgba(0, 0, 0, 0.29);
          border-bottom: 1px solid #d5d5d5; }
          .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .scroll-container .ui.table {
            margin-top: 0; }
            .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .scroll-container .ui.table .resource-icon {
              margin-left: 1.5em; }
        .qs-sidebar .ui.vertical.menu .select-wrap .nav-search .no-results {
          margin-left: 1.2em; }
    .qs-sidebar .ui.vertical.menu .item {
      margin: 0;
      padding: 13px 16px; }
      .qs-sidebar .ui.vertical.menu .item.main {
        border-radius: 0;
        background-color: #d0d0d0; }
        .qs-sidebar .ui.vertical.menu .item.main .header {
          font-weight: bold; }
      .qs-sidebar .ui.vertical.menu .item .header {
        font-weight: normal;
        margin: 0; }
      .qs-sidebar .ui.vertical.menu .item:before {
        background: transparent;
        height: 0; }
    .qs-sidebar .ui.vertical.menu a.item {
      font-weight: normal;
      color: #555;
      border-top: 1px dashed #d5d5d5; }
      .qs-sidebar .ui.vertical.menu a.item.insights, .qs-sidebar .ui.vertical.menu a.item.users, .qs-sidebar .ui.vertical.menu a.item.user {
        border-top: none; }
      .qs-sidebar .ui.vertical.menu a.item:first-child {
        border-top: none; }
      .qs-sidebar .ui.vertical.menu a.item:last-child {
        border-bottom: 1px solid #d5d5d5; }
  .qs-sidebar .qs-sidebar-top {
    flex: 1 1; }
  .qs-sidebar .qs-sidebar-bottom {
    flex: 0 0 auto; }
  .qs-sidebar.collapsed {
    width: 50px; }

.qs-sidebar-menu {
  height: 100%;
  width: 250px;
  border-radius: 0;
  border-top: none; }

/* Example Styles for React Tags*/
.app {
  padding: 40px;
  text-align: center; }

/* Example Styles for React Tags*/
.container {
  margin: auto;
  width: 50%; }

.ReactTags__tags {
  position: relative;
  display: flex;
  flex-direction: row; }

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none; }

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block; }

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
  min-width: 150px; }

.ReactTags__editInput {
  border-radius: 1px; }

.ReactTags__editTagInput {
  display: inline-flex; }

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px; }

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer; }

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute; }

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px; }

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0; }

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer; }

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white; }

html, body {
  margin: 0;
  padding: 0;
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  overflow: hidden; }

#root {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  font-family: sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 1;
  height: 100%; }

* {
  box-sizing: inherit; }

.itemCard {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 3px solid #F2F2F2;
  border-radius: 5px;
  opacity: 1; }

.mobile-report-menu {
  display: flex;
  padding: 7px; }
  .mobile-report-menu button {
    height: 52px;
    border: 1px solid #c7c7c7 !important;
    background-color: #e9e9e9 !important; }
    .mobile-report-menu button span {
      color: #111111; }
  .mobile-report-menu .date-btn {
    display: flex;
    width: 100%;
    align-items: center; }
    .mobile-report-menu .date-btn span {
      flex-grow: 1;
      text-align: left; }
  .mobile-report-menu .info-btn {
    width: 52px;
    margin-right: 0; }

.mobile-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: hidden;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif; }
  .mobile-modal .menu {
    justify-content: space-evenly; }
    .mobile-modal .menu a {
      background-color: #e9e9e9 !important;
      height: 52px;
      border-bottom-width: 3px !important;
      flex: 1 1 !important;
      justify-content: center;
      font-weight: bold !important; }
      .mobile-modal .menu a.active {
        border-bottom-color: #a8a8a8 !important; }
      .mobile-modal .menu a:active {
        border-color: transparent !important; }
  .mobile-modal .pane {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f7f7f7;
    padding: 9px; }
    .mobile-modal .pane.with-header {
      top: 52px; }
  .mobile-modal .pane-container {
    height: 100%;
    padding: 18px;
    border: #dbdbdb 1px solid;
    border-radius: 3px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto; }
    .mobile-modal .pane-container .sub.header {
      margin-top: 20px;
      text-transform: none; }
    .mobile-modal .pane-container .pane-body {
      margin-top: 20px;
      margin-bottom: 16px;
      flex-grow: 1; }
      .mobile-modal .pane-container .pane-body.scroll {
        overflow: auto;
        margin-top: 10px;
        margin-bottom: 16px; }
      .mobile-modal .pane-container .pane-body button,
      .mobile-modal .pane-container .pane-body input {
        -webkit-appearance: none;
        color: #111111;
        margin-bottom: 4px;
        background-color: white;
        border: 1px solid #c7c7c7;
        border-radius: 5px;
        height: 36px;
        position: relative;
        text-align: center;
        font-weight: bold; }
        .mobile-modal .pane-container .pane-body button i,
        .mobile-modal .pane-container .pane-body input i {
          position: absolute;
          left: 15px; }
        .mobile-modal .pane-container .pane-body button.selected,
        .mobile-modal .pane-container .pane-body input.selected {
          background-color: #e9e9e9; }
      .mobile-modal .pane-container .pane-body i.icon {
        height: 36px; }
      .mobile-modal .pane-container .pane-body .toggle.checkbox {
        margin-top: 24px; }
      .mobile-modal .pane-container .pane-body .label {
        width: 100%;
        text-align: center; }
  .mobile-modal label {
    font-weight: bold;
    text-decoration: underline; }
    .mobile-modal label.padded {
      line-height: 44px; }
  .mobile-modal span.label {
    font-weight: bold; }
    .mobile-modal span.label:after {
      content: " - "; }

.ui.icon.message > .iconPro:not(.close) {
  display: block;
  flex: 0 0 auto;
  width: auto;
  line-height: 1;
  vertical-align: middle;
  font-size: 1em;
  opacity: 0.8; }

.ui.message > .iconPro {
  margin-right: 0.6em; }

.required.noLabel label {
  display: none !important; }

.ui.modal,
.ui.tiny.modal {
  position: relative; }

.lightText {
  color: #a8a8a8; }

/* Form Overrides for formsy-semantic-ui-validation */
form .fields.pwdGroup {
  position: relative; }
  form .fields.pwdGroup .showPwdBtn {
    position: absolute;
    left: 20px;
    top: 34px;
    width: 18px;
    height: 20px;
    z-index: 100; }
  form .fields.pwdGroup input {
    padding-left: 35px !important; }
  form .fields.pwdGroup .description {
    font-size: 13px;
    clear: left;
    margin: 4px 0 20px 0px;
    color: #bbb; }

.ui.form .field.primary .ui.button {
  color: #fff; }

form.styled .ui.form .field.error .input,
form.styled .ui.form .field.error label,
form.styled .ui.form .fields.error .field .input,
form.styled .ui.form .fields.error .field label {
  color: rgba(0, 0, 0, 0.87); }

form.styled .ui.form .field.error .ui.error.input input[type="text"],
form.styled .ui.form .field.error .ui.error.input input[type="password"],
form.styled .ui.form .field.error .ui.error.input input[type="email"],
form.styled .ui.form .field.error .error.field .label {
  background-color: transparent !important; }

form.styled .ui.form .field.error .ui.dropdown .default.text:not(.menu) {
  background-color: transparent !important;
  color: rgba(238, 190, 190, 0.87); }

.ui.accordion .disabledText {
  color: #8c8c8c; }

/* -- End of form overrides --*/

.mobile-advert {
  background: linear-gradient(#fff, #f0f0f0);
  height: 100%; }
  .mobile-advert .italic {
    font-style: italic; }
  .mobile-advert .device-image {
    background-position: top center;
    background-size: contain;
    height: 1300px;
    background-repeat: no-repeat; }
  .mobile-advert .toplogo {
    height: 130px;
    background: url("https://cdn.quikserve.com/logos/quikserve/qs-logo-standard.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%; }

