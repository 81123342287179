@import "../sizes";
@import "../colors";

.ui.icon.message > .iconPro:not(.close) {
  display: block;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  line-height: 1;
  vertical-align: middle;
  font-size: 1em;
  opacity: 0.8;
}

.ui.message > .iconPro {
  margin-right: 0.6em;
}

.required.noLabel label {
  display: none !important;
}

.ui.modal,
.ui.tiny.modal {
  // fix close button
  position: relative;
}

.lightText {
  color: #a8a8a8;
}

// SUI modal positioning appears to be fixed
// .ui.tiny.modal.centerTinyModal {
//   margin: 100px auto 0 auto;
// }

/* Form Overrides for formsy-semantic-ui-validation */

form .fields.pwdGroup {
  position: relative;
  .showPwdBtn {
    position: absolute;
    left: 20px;
    top: 34px;
    width: 18px;
    height: 20px;
    z-index: 100;
  }
  input {
    padding-left: 35px !important;
  }
  .description {
    font-size: 13px;
    clear: left;
    margin: 4px 0 20px 0px;
    color: #bbb;
  }
}

.ui.form .field.primary .ui.button {
  color: #fff;
}

form {
  &.styled {
    // Remove form background colors, make text consistent
    .ui.form .field.error .input,
    .ui.form .field.error label,
    .ui.form .fields.error .field .input,
    .ui.form .fields.error .field label {
      color: rgba(0, 0, 0, 0.87);
    }

    .ui.form .field.error .ui.error.input input[type="text"],
    .ui.form .field.error .ui.error.input input[type="password"],
    .ui.form .field.error .ui.error.input input[type="email"],
    .ui.form .field.error .error.field .label {
      background-color: transparent !important;
    }

    .ui.form .field.error .ui.dropdown .default.text:not(.menu) {
      background-color: transparent !important;
      color: rgba(238, 190, 190, 0.87);
    }
  }
}

.ui.accordion .disabledText {
  color: #8c8c8c;
}
/* -- End of form overrides --*/
