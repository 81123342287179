.qs-panel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  margin-top: 50px;
  background-color: rgba(1,1,1,0);
  visibility: hidden;
  transition: visibility 500ms linear, background-color 500ms linear;
  // display: none;
  z-index: 1000000;
  &.visible {
    visibility: visible;
    background-color: rgba(0,0,0,0.5);
    transition: visibility 500ms linear, background-color 500ms linear;
  }

  .qs-panel {
    width: 0px;
    height: calc(100vh - 50px);
    position: absolute;
    top: 0;
    left: auto;
    right: 0;
    background-color: #FFF;
    opacity: 1;
    visibility: visible;
    overflow-y: auto;
    overflow-x: hidden;
  }
}