@import './sizes';
@import './colors';

.qs-login-container {
  width: 100vw;
  height: 100vh;
  padding: $safe-area-padding;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 35%, rgba(243,243,243,1) 100%);
  overflow: hidden;

  .qs-pin-input {
    input {
      text-align: center;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number],
    input[type=number] {
      -webkit-text-security: disc;
    }
  }

  a.link {
    cursor:pointer;
  }

  .qs-user-glyph {
  }
}

.ui.container {
  &.account-screen {
    width: 100%;
    height: 100vh;
    background: -webkit-linear-gradient(top, #ffffff 0%,#ebebeb 50%,#f0f0f0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%,#ebebeb 50%,#f0f0f0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    img.ui.image {
      &.account-logo-medium {
        width: 268px; 
        height: 52px;  
        object-fit: cover;  
        margin-top: 50px;  
        margin-bottom: 30px; 
      }
    }
    
    h2.ui.header {
      &.account-header {
        color: $dark-text-color;
        font-size: 21px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 1em 0;
      }
    }

    .info-box {
      width: 788px;
      margin: 0 auto;
      padding-bottom: 2em;
      border: 1px solid $account-border;
      text-align: center;

      .form-container {
        width: 630px;
        margin: 0 auto;
        text-align: left;

        p:first-child {
          margin-top: 1.5em;
        }
      }
    }
  }
}
