@import './sizes';

@import  url(https://fonts.googleapis.com/css?family=Montserrat);

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#root {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  font-family: sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 1;
  height: 100%;
}

* {
  box-sizing: inherit;
}

.itemCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 3px solid #F2F2F2;
    border-radius: 5px;
    opacity: 1;
}